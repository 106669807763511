<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
        </div> -->
     


<div style="max-width:700px; margin:4% 3%"     v-if="windowWidth < 768">
    
      <h3 class="mt-2 mb-2">  <img src="../../../assets/images/back_mob.png" loading="lazy" alt="" height="18" 
       class="pr-2  mb-1"  @click="gotoHome"> {{dollarProduct.name}}</h3>
      <!-- <p class="mb-3">Imagine Africa</p> -->
   <div style="background:#fff; padding:10px; min-height:40px ;display:flex; align-items:center; border-radius:10px">
          <div style="flex-basis:50%">
                <p style="color:#828282">Wallet Balance</p>
              <p style="color:#269696;font-size:18px" class="pr-3"><span>&#8358;</span>{{formatAmount(userBal)}}</p>

          </div>
          <div style="flex-basis:50%; display:flex; justify-content:flex-end; align-items:center    " >
              <button style="background:#fff; border:1px solid #E0E0E0; padding:10px; border-radius:5px; font-size:12px" @click="sendToWallet">
Add Funds
              </button>
          </div>
      </div>
</div>
<div class="invest-container" >
<div style="padding:20px" v-if="windowWidth > 767">
      <img @click="gotoHome" class="point-cursor"
                      src="../../../assets/images/back1.png"
                      height="50"
                    
                    >
</div>


<div style="max-width:700px; margin:0 auto">
      <h3 class="mt-4 mb-4" v-if="windowWidth > 767">{{dollarProduct.name}}</h3>
      <div style="background:#F5F5F5; padding:10px; min-height:40px ;display:flex; align-items:center; " v-if="windowWidth > 767">
          <div style="flex-basis:50%">
                <p style="color:#828282">Wallet Balance</p>
          </div>
          <div style="flex-basis:50%; display:flex; justify-content:flex-end; align-items:center    " >
              <p style="color:#269696;font-size:18px" class="pr-3"><span>&#8358;</span>{{formatAmount(userBal)}}</p>
              <button style="background:#fff; border:1px solid #fff; border-radius:5px"  @click="sendToWallet">
Add Funds 
              </button>
          </div>
      </div>
      <div style="min-height:40px ;display:flex; justify-content:space-between; align-items:center;flex-wrap:wrap" class="mt-4">
          <div class="mr-4 flex-50-100">
              <h3>How much do you intend to invest?</h3>
              <p style="color:#4F4F4F" class="pb-3">This fund is denominated in dollars 
but you pay in Naira.</p>
          </div>
          <div class="flex-40-100" style=" display:flex; justify-content:flex-end; flex-direction:column; " >
              <p class="text-right">${{dollarBondData.eubond_min_amount}} Minimum</p>
             <input type="text" class="enable-input text-center" style="border-radius:10px; font-weight:600; min-height:55px" :placeholder="'$'+dollarBondData.eubond_min_amount+' minimum'"   @keyup="getFundValue"
                      :value="returnMinAmount"   autofocus>
             <div style="min-height:30px;background:#E6F4FF;padding:10px;border-radius: 10px; display:flex; align-items:center">
                 <div style="flex-basis: 65%; "> 
                      <p style="color:#269696;font-size:18px" ><b><span>&#8358;</span> {{formatAmount(returnNairaEquiv)}} </b></p>
                 </div>
                 <div style="flex-basis: 35%; display:flex; justify-content:flex-end">
                      <p style="color:#4F4F4F">1$ = <span>&#8358;</span>{{dollarBondData.usd_exchange_rate_buy}}</p>
                 </div>
             </div>
          </div>
      </div>
      <div style="min-height:40px ;display:flex; justify-content:space-between; margin-top:50px;flex-wrap:wrap" >
          <div class="mr-4 flex-50-100" v-if="windowWidth > 767">
              <h5 style="font-weight:600; font-size:18px" class="pb-2">Tag your Investment</h5>
        <p style="color:#4F4F4F" class="pb-2">Choose a name to help you stay focused </p>
        <p style="color:#4F4F4F"><b>(optional)  </b></p>
          </div>
          <div class="flex-40-100" style="display:flex; justify-content:flex-end; flex-direction:column; " >

              <div style="display:flex" class="mb-2" v-if="windowWidth < 768">
                  <div  style="flex-basis:50%; color:#828282">
                         <p>Tag your Investment</p>
                  </div>
                  <div style="flex-basis:50%">
                         <p class="text-right"><b>Optional </b></p>
                  </div>
              </div>
             <select type="text" class="enable-input text-center "  :class="{'black-col': userHasSelectTag}"
             style="border-radius:10px; font-weight:600" @change="getTagSelected($event)">
             <option  disabled selected>School, New Home, Travel</option>
                    <option
                          v-for="tag in dollarBondData.tags"
                          :value="tag.id"
                          :key="tag.id"
                        
                        >
{{tag.name}}
                           </option>

             </select>
             <div style="min-height:30px;border-radius: 10px; display:flex; align-items:center; justify-content:flex-end" class="pt-2 mb-3">
                  <button class="inv-btn"    :class="{'fade-cont': returnPurchaseStatus }" @click="saveBondData">
Continue    <img
                      src="../../../assets/images/chevright.png"
                      height="15"
                    class="pl-3"
                    > 
              </button>
             </div>
          </div>
      </div>
</div>
</div>

      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";
import http from "../../../http/index";
import api from "../../../apis/index";
export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
      minAmount: "",
userHasSelectTag:false,
      investAmount:'',
      nairaEquiv:0,
      stopPurchase:true,
        tagSelected:'',
    };
  },
  methods: {
    sendToMarketPlace() {
      this.$router.push("/market");
    },
    calcNairaValue(amount) {
        if(isNaN(amount)){
            this.nairaEquiv = 0
            return
        }
 this.nairaEquiv =  parseFloat(amount)*parseFloat(this.dollarBondData.usd_exchange_rate_buy)
    },


    getFundValue(e) {
      let fundValue = e.target.value;
      e.target.value = parseInt(
        fundValue.replace(/\D/g, ""),
        10
      ).toLocaleString();

      this.minAmount = e.target.value;

      let inputValue = this.minAmount.toString().replace(/,/g, "");

      this.calcNairaValue(inputValue)

      if (isNaN(this.minAmount.toString().replace(/,/g, ""))) {
        this.minAmount = "";
      }

// console.log(this.userBal, this.returnNairaEquiv,'checking')

if(parseFloat(inputValue) >= parseFloat(this.dollarBondData.eubond_min_amount) && parseFloat(this.returnNairaEquiv) <= parseFloat(this.userBal)){

    this.stopPurchase = false

}

else{
  this.stopPurchase = true
}


    },
  
    sendToWallet() {
      this.$router.push({
        path: "/wallet",
        query: { openFundWallet: true, fundAmount: this.amount }
      });
    },
    gotoBackHome() {
      this.$router.push({
        path: "/dollar/home",
      });
    },

    gotoHome(){

      this.$router.push({path: "/dollar/home",});
    },
 getTagSelected(e) {
     // this.$helpers.log(e.target.value);
   this.userHasSelectTag = true
      let tag = this.dollarBondData.tags.filter(prod => {
        return e.target.value == prod.id;
      });

this.tagSelected = tag[0].id
    
    },
   saveBondData(){


       let nairaVal  = parseFloat(this.dollarBondData.eubond_processing_rate)*0.01
       nairaVal = nairaVal*parseFloat(this.nairaEquiv)
       
let totalBalance = parseFloat(nairaVal)+parseFloat(this.nairaEquiv)
       let bondData = {

           amountInUsd : this.minAmount.toString().replace(/,/g, ""),
            nairaEquiv:this.nairaEquiv,
            tagSelected:this.tagSelected,
            percentValueInNaira:nairaVal,
            totalBalanceToBePaid:totalBalance


       }

       this.$store.commit('market/saveBondDataFromComponent', bondData)
          this.$router.push({  path: "/dollar/tenor"});
   }

  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
   
      dollarBondData: state => state.market.dollarBondData,
 
     
    }),
    returnMinAmount() {
      if (this.minAmount.toString().replace(/,/g, "") % 1 !== 0) {
        return this.minAmount.toString().replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
      } else {
        return this.minAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    returnNairaEquiv() {
     return this.nairaEquiv
    },


    returnPurchaseStatus() {
     return this.stopPurchase 
    },
  },
  mounted() {

    let heading = {
      main: this.tran.euro_bond
    };
     this.$store.dispatch("market/getEuroBondData");

      this.$store.commit("market/savePageHeading", heading);
  },

 
};
</script>

<style scoped>
select{
    min-height:55px;
  padding: 5px;
  color: #BDBDBD;
    font-family: Circular;
}

.black-col{

    color:#000 !important;
}


select option { color: black; }
select option:first-child{
  color: #BDBDBD;
}
.invest-container{

    background:#fff;
    min-height: 90vh;
    margin: 5%;
    padding: 10px;
    margin-top:2%;
    border-radius: 10px;
}
.flex-50-100{
    flex-basis:50%
}

.flex-40-100{

    flex-basis:40%
}

.inv-btn{
background:#0094FF; border:1px solid #fff; border-radius:5px; min-height:55px; min-width:170px; color:#fff;
 padding: 10px 20px;
}
@media(max-width:1500px){
.invest-container{
    zoom:80%;
}
}
@media(max-width:767px){
.flex-50-100, .flex-40-100{
    flex-basis:100% !important;
}

.inv-btn{
min-width:100%; 
}
.invest-container{
        min-height: 50vh;
        margin: 3%;
}
}
.fade-cont{
    opacity:0.5;
  pointer-events: none !important;
  transition: .6s;
}
.inv-btn{
      transition: .6s;
}
</style>
