<template>
<div>
    <DollarInvest />
</div>
</template>

<script>
import DollarInvest from '../../components/dashboard/dollarAsset/DollarInvest.vue'
export default {
    components: {
        DollarInvest
    }
}
</script>

